import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

// Import your components
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Fee from './components/Fee';
import Programs from './components/Programs';
import Application from './components/Application';
import Refund from './components/Refund';
import Terms from './components/Terms';
import Course from './components/Course';
// Configure NProgress settings
NProgress.configure({ showSpinner: false, trickleSpeed: 200 });

// Component to handle route progress bar
const RouteProgress = () => {
    const location = useLocation();

    useEffect(() => {
        NProgress.start();
        
        // Add a delay to give the progress bar time to be visible
        const timer = setTimeout(() => {
            NProgress.done();
        }, 500); // Adjust this delay if needed

        // Clean up function to stop NProgress on unmount
        return () => {
            clearTimeout(timer);
            NProgress.done();
        };
    }, [location]);

    return null;
};

const App = () => {
    return (
        <div>
            <Header />
            <RouteProgress />
            <div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/application" element={<Application />} />
                    <Route path="/fee" element={<Fee />} />
                    <Route path="/programs" element={<Programs />} />
                    <Route path="/course/:slug" element={<Course />} />
                    <Route path="/refund" element={<Refund />} />
                    <Route path="/terms" element={<Terms />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
};

export default App;
