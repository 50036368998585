import React, { useState, useEffect } from 'react';
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
    const [generalDetails, setGeneralDetails] = useState(null);  // State to store fetched data
    const [error, setError] = useState(null);  // State to handle errors

    useEffect(() => {
        // Fetch data from the API
        fetch('https://admin.coderecordz.online/api/general')
            .then(response => response.json())
            .then(data => {
                setGeneralDetails(data);  // Store the fetched data in state
            })
            .catch(err => {
                setError(err);  // Handle any errors
                console.log(error);
            });
    });  // Empty dependency array to run the effect only once on component mount


    return (
        <div>
            <div className="container-fluid topbar text-light py-2">
                <div className="row align-items-center">
                    <div className="col-md-6 col-sm-12 mb-2 mb-md-0 text-center text-md-start">
                        <Link to="/login" className="me-3 text-decoration-none"><i className="fa fa-sign-in"></i> Login</Link>
                        <Link to="/application" className="me-3 text-decoration-none"><i className="fa fa-file"></i> Apply Online</Link>
                        <Link to="/terms" className="text-decoration-none"><i className="fa fa-key"></i> Terms & Conditions</Link>
                    </div>
                    <div className="col-md-6 col-sm-12 text-center text-md-end">
                        {generalDetails && (
                            <>
                                <span className="me-3"><i className="fa fa-envelope"></i> {generalDetails.email}</span>
                                <span className="me-3"><i className="fa fa-whatsapp"></i> {generalDetails.whatsapp}</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Brand Logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNavbar" aria-controls="mainNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mainNavbar">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active text-decoration-none" aria-current="page" to="/"><i className="fa fa-home me-1"></i> Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-decoration-none" to="/about"><i className="fa fa-info-circle me-1"></i> About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-decoration-none" to="/programs"><i className="fa fa-graduation-cap me-1"></i> Our Programs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-decoration-none" to="/fee"><i className="fa fa-bank me-1"></i> Fee Structure</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-decoration-none" to="/contact"><i className="fa fa-envelope me-1"></i> Contact</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="btn btn-sm btn-danger" aria-current="page" to="/application"><i className="fa fa-send"></i> Apply Online </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;
