import React, { useState, useEffect } from 'react';

const Application = () => {
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        whatsapp: '',
        course: '',
        city: '',
        country: '',
        how_you_know_us: ''
    });

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [courses, setCourses] = useState([]); // State to store fetched courses

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setLoading(true);
        setErrors(null);  // Reset errors on new submission
    
        try {
            // Log formData before sending
            console.log('Submitting formData:', formData);
    
            const response = await fetch('https://admin.coderecordz.online/api/request/online', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formData)  // Send the formData here
            });
    
            // Log the response to inspect any errors returned by the API
            const data = await response.json();
            console.log('API Response:', data);
    
            if (!response.ok) {
                // If validation errors exist
                setErrors(data.errors || {});
            } else {
                // If submission is successful
                setMessage(data.message);
                setFormData({
                    full_name: '',
                    email: '',
                    whatsapp: '',
                    course: '',
                    city: '',
                    country: '',
                    how_you_know_us: ''
                });
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            setErrors({ general: 'Something went wrong, please try again later.' });
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        // Fetch the courses when the component mounts
        fetch("https://admin.coderecordz.online/api/courses")
            .then(response => response.json())
            .then(data => setCourses(data)) // Update the courses state
            .catch(err => setErrors({ general: 'Failed to load courses. Please try again later.' }));
    }, []); // Empty dependency array to fetch once on mount

    return (
        <div>
            <div className="jumbotron bg-danger">
                <div className="container text-white p-5">
                    <h2> <i className="fa fa-send"></i> Apply Online For Any Course</h2>
                    <p>
                    1. Fill in the online application form below. Provide your details, such as full name, email, WhatsApp number, and your preferred course. <br />
                    2. Mention how you heard about us and share your location details (city and country).  <br />
                    3. Submit your application, and our team will review your details. Once accepted, you'll receive confirmation along with further instructions to get started.
                    </p>
                </div>
            </div>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className='card-header bg-danger'>
                                <label className="text-white"> <i className='fa fa-send'></i> Apply Online</label>
                            </div>
                            <form className='card-body' onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="fullName" className="form-label">
                                        <i className="fa fa-user-circle"></i> Full Name
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors?.full_name ? 'is-invalid' : ''}`}
                                        id="fullName"
                                        name="full_name"
                                        value={formData.full_name}
                                        onChange={handleInputChange}
                                        placeholder="Enter your full name"
                                    />
                                    {errors?.full_name && <div className="invalid-feedback">{errors.full_name[0]}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        <i className="fa fa-envelope"></i> Email
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control ${errors?.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder="Enter your email"
                                    />
                                    {errors?.email && <div className="invalid-feedback">{errors.email[0]}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="whatsApp" className="form-label">
                                        <i className="fa fa-whatsapp"></i> WhatsApp
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors?.whatsapp ? 'is-invalid' : ''}`}
                                        id="whatsApp"
                                        name="whatsapp"
                                        value={formData.whatsapp}
                                        onChange={handleInputChange}
                                        placeholder="Enter your WhatsApp number"
                                    />
                                    {errors?.whatsapp && <div className="invalid-feedback">{errors.whatsapp[0]}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="course" className="form-label">
                                        <i className="fa fa-book"></i> Select Course
                                    </label>
                                    <select
                                        className={`form-control ${errors?.course ? 'is-invalid' : ''}`}
                                        id="course"
                                        name="course"
                                        value={formData.course}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select a course</option>
                                        {courses.length > 0 ? (
                                            courses.map(course => (
                                                <option key={course.id} value={course.id}>
                                                    {course.title}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>Loading courses...</option>
                                        )}
                                    </select>
                                    {errors?.course && <div className="invalid-feedback">{errors.course[0]}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="city" className="form-label">
                                        <i className="fa fa-building"></i> City
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors?.city ? 'is-invalid' : ''}`}
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                        placeholder="Enter your city"
                                    />
                                    {errors?.city && <div className="invalid-feedback">{errors.city[0]}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="country" className="form-label">
                                        <i className="fa fa-globe"></i> Country
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors?.country ? 'is-invalid' : ''}`}
                                        id="country"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleInputChange}
                                        placeholder="Enter your country"
                                    />
                                    {errors?.country && <div className="invalid-feedback">{errors.country[0]}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="reference" className="form-label">
                                        <i className="fa fa-question-circle"></i> How You Know Us?
                                    </label>
                                    <select
                                        className={`form-control ${errors?.how_you_know_us ? 'is-invalid' : ''}`}
                                        id="reference"
                                        name="how_you_know_us"
                                        value={formData.how_you_know_us}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select an option</option>
                                        <option value="socialMedia">Social Media</option>
                                        <option value="friend">Friend</option>
                                        <option value="searchEngine">Search Engine</option>
                                        <option value="other">Other</option>
                                    </select>
                                    {errors?.how_you_know_us && <div className="invalid-feedback">{errors.how_you_know_us[0]}</div>}
                                </div>

                                <button type="submit" className="btn btn-danger w-100" disabled={loading}>
                                    {loading ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa fa-paper-plane"></i>} 
                                    {loading ? 'Submitting...' : 'Submit Application'}
                                </button>

                                {message && <div className="alert alert-success mt-3">{message}</div>}
                                {errors?.general && <div className="alert alert-danger mt-3">{errors.general}</div>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Application;
