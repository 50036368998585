import React from 'react';

const Terms = () => {
    return (
        <div>
            <div className="jumbotron bg-danger">
              <div className="container text-white p-5">
                <h2> <i className="fa fa-envelope"></i> Terms And Conditions </h2>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor pariatur, quod tempora beatae soluta sunt cum totam quibusdam vitae ad veritatis voluptate perferendis repellendus quam sint facere mollitia non saepe quisquam obcaecati distinctio. In quam architecto maiores dolorum, praesentium et doloribus, dolore commodi rerum, atque animi dolores? Dolor, voluptates. Quisquam.</p>
              </div>
            </div>
            <div className='container mt-2 mb-3'>
                <div className='col-lg-10 col-sm-10 col-md-10'>
                    <div>
                        <div className="accordion" id="termsAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        1. General Information
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        Welcome to CodeRecordz. These Terms and Conditions govern your access and use of our website and services. By accessing our website, you agree to comply with these terms.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        2. Course Purchase and One-Time Payment
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        All courses on CodeRecordz are offered for a one-time purchase fee. No recurring fees or subscriptions apply, ensuring that you only pay once for access to any course.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        3. Lifetime Access
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        Upon purchase, you will have lifetime access to the course content, which includes videos, resources, and updates relevant to the purchased course.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        4. Refund Policy
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        Refunds are available only within a specified period after purchase. Please refer to our refund policy for detailed information on eligibility and process.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        5. User Conduct
                                    </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        Users must respect the intellectual property and terms of use of CodeRecordz. Any misuse of content, including redistribution or resale, is prohibited.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSix">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        6. Intellectual Property Rights
                                    </button>
                                </h2>
                                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        All course content is the property of CodeRecordz. Unauthorized reproduction or sharing of content without permission is strictly prohibited.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSeven">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        7. Content Updates
                                    </button>
                                </h2>
                                <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        CodeRecordz reserves the right to update course content as necessary. Any updates or improvements will be accessible to users without additional charges.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingEight">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                        8. Account Security
                                    </button>
                                </h2>
                                <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        Users are responsible for maintaining the confidentiality of their account information. CodeRecordz is not liable for any unauthorized access to your account.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingNine">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                        9. Limitation of Liability
                                    </button>
                                </h2>
                                <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        CodeRecordz is not liable for any indirect, incidental, or consequential damages arising from the use of our website or services.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTen">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                        10. Contact Information
                                    </button>
                                </h2>
                                <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#termsAccordion">
                                    <div className="accordion-body">
                                        If you have questions or need further information about these terms, please contact us at support@coderecordz.com.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;
