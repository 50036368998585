import React, { useState, useEffect } from "react";

const Contact = () => {
    const [contactInfo, setContactInfo] = useState(null); // State to store API data
    const [loading, setLoading] = useState(true); // State to manage loading
    const [error, setError] = useState(null); // State to manage errors

    useEffect(() => {
        // Fetch API data when the component mounts
        fetch("https://admin.coderecordz.online/api/general")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch contact information");
                }
                return response.json();
            })
            .then((data) => {
                setContactInfo(data); // Update the state with fetched data
                setLoading(false);
            })
            .catch((err) => {
                setError(err.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading contact information...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div className="jumbotron bg-danger">
                <div className="container text-white p-5">
                    <h2> <i className="fa fa-envelope"></i> Contact Us</h2>
                    <p>
                        We're here to help you with anything you need. Whether you're looking to inquire about our courses, need assistance with your learning experience, or want to partner with us, don't hesitate to reach out. Our dedicated support team is ready to assist you and ensure your success on our platform.
                    </p>
                </div>
            </div>

            <div className="container my-5">
                <div className="contact-info">
                    <h2 className="mb-4"> <i className="fa fa-handshake-o"></i> Get In Touch With Us</h2>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-4 mb-4">
                        <div className="contact-card">
                            <i className="fa fa-map-marker"></i>
                            <h5>Our Location</h5>
                            <p>
                                {contactInfo?.title}
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="contact-card">
                            <i className="fa fa-envelope"></i>
                            <h5>Email Us</h5>
                            <p>
                                {contactInfo?.email}
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="contact-card">
                            <i className="fa fa-phone"></i>
                            <h5>Call Us</h5>
                            <p>
                                WhatsApp: {contactInfo?.whatsapp} <br />
                                Landline: {contactInfo?.landline}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="justify-content-center mt-5 bg-light p-4" id="contact-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 contact-info">
                            <h2> <i className="fa fa-volume-up"></i> We'd Love to Hear From You</h2>
                            <p>
                                Whether you have a question about our courses, need technical support, or want to collaborate with us, we're here for you. Reach out to us today, and we'll make sure to get back to you as soon as possible.
                            </p>
                            <div className="social-icons">
                                <a href={contactInfo?.website} target="_blank" rel="noreferrer">
                                    <i className="fa fa-globe"></i> Visit Website
                                </a>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="contact-form">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label"> <i className="fa fa-user-circle"></i> Your Name</label>
                                        <input type="text" className="form-control" id="name" placeholder="Your name" />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label"> <i className="fa fa-envelope"></i> Email Address</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter email address" />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="message" className="form-label"> <i className="fa fa-send"></i> Message</label>
                                        <textarea className="form-control" id="message" rows="4" placeholder="Write message"></textarea>
                                    </div>

                                    <button type="submit" className="btn btn-danger"> <i className="fa fa-send"></i> Send Message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
