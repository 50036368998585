import { useState, useEffect } from "react";

const Fee = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch data from the API
        const fetchCourses = async () => {
            try {
                const response = await fetch("https://admin.coderecordz.online/api/courses");
                const data = await response.json();
                setCourses(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching courses:", error);
                setLoading(false);
            }
        };

        fetchCourses();
        console.log("https://admin.coderecordz.online/api/courses");
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="jumbotron bg-danger">
                <div className="container-fluid text-white p-5">
                    <h2> <i className="fa fa-bank"></i> Fee Structure Work</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus totam accusamus aliquid deserunt illo sit error minus unde nesciunt ex nam consectetur pariatur tempore ipsa libero, tempora nisi dolorum suscipit, quos veniam reiciendis rerum id. Ipsam delectus voluptates quis aliquid quo praesentium, nemo cum voluptatibus sapiente sed saepe rem illo!
                    </p>
                </div>
            </div>
            <div className="container-fluid my-5">
                <table className="table table-bordered table-hover table-striped">
                    <thead className="bg-danger text-white">
                        <tr>
                            <th><i className="fa fa-book"></i> Course Name</th>
                            <th><i className="fa fa-clock-o"></i> Lectures </th>
                            <th><i className="fa fa-th-list"></i> Sections</th>
                            <th><i className="fa fa-money"></i> Regular Fee</th>
                            <th><i className="fa fa-tag"></i> Discount</th>
                            <th><i className="fa fa-usd"></i> Amount</th>
                            <th><i className="fa fa-check-circle"></i> Enroll Now</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses.map((course) => (
                            <tr key={course.id}>
                                <td>{course.title}</td>
                                <td>{course.lectures}</td>
                                <td>{course.sections}</td>
                                <td>{course.regular_fee} Pkr</td>
                                <td>{course.discount}%</td>
                                <td>{course.fee } Pkr</td>
                                <td><a href="/" className="btn btn-danger btn-sm"><i className="fa fa-pencil"></i> Enroll Now</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Fee;
