const Footer = () => {
    return (
        <div>
            <footer className="bg-dark text-white pt-5 pb-4">
                <div className="container text-md-start">
                    <div className="row">
                        <div className="col-md-8 mb-4">
                            <h5>Top companies choose <span className="text-primary">Our Platform</span> to build in-demand career skills.</h5>
                        </div>
                        <div className="col-md-4 text-md-end">
                            <img src="https://via.placeholder.com/80" alt="Logo 1" className="me-2" />
                            <img src="https://via.placeholder.com/80" alt="Logo 2" className="me-2" />
                            <img src="https://via.placeholder.com/80" alt="Logo 3" className="me-2" />
                            <img src="https://via.placeholder.com/80" alt="Logo 4" className="me-2" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <h6>Company</h6>
                            <ul className="list-unstyled">
                                <li>Udemy Business</li>
                                <li>Teach on Udemy</li>
                                <li>Get the app</li>
                                <li>About us</li>
                                <li>Contact us</li>
                            </ul>
                        </div>
                        <div className="col-md-3 mb-4">
                            <h6>Community</h6>
                            <ul className="list-unstyled">
                                <li>Careers</li>
                                <li>Blog</li>
                                <li>Help and Support</li>
                                <li>Affiliate</li>
                                <li>Investors</li>
                            </ul>
                        </div>
                        <div className="col-md-3 mb-4">
                            <h6>Legal</h6>
                            <ul className="list-unstyled">
                                <li>Terms</li>
                                <li>Privacy policy</li>
                                <li>Cookie settings</li>
                                <li>Sitemap</li>
                                <li>Accessibility statement</li>
                            </ul>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-4">
                            <p className="text-white-50 mb-0"> Coderecordz.online &copy; 2024 Company, Inc | Project By <i className="fa fa-globe"></i> Webeducatorz.org</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
