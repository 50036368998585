const About = () => {
    return (
        <div>  
            <div className="jumbotron bg-primary p-5 bg-light">
                <div className="container p-4">
                    <div className="row">
                        <div className="col-lg-6">
                            <div>
                                <h1 className="fw-bold text-danger">
                                    <i className="fa fa-play-circle"></i> The Leading Global Marketplace for Learning and Instruction
                                </h1>
                                <p className="stats-text text-muted">
                                    Welcome to our online e-learning platform, where we offer a variety of professionally recorded courses designed to help you excel. Every course comes with multiple sections, and we provide one live class for each section to ensure you have a thorough understanding and can engage directly with your instructors and peers. Whether you're looking to enhance your skills or dive into a new subject, we are here to support your learning journey every step of the way.
                                </p>
                                <ul className="list-unstyled text-start stats-text mx-auto">
                                    <li><i className="bi bi-check-circle-fill text-success"></i> Wide range of courses with lifetime access.</li>
                                    <li><i className="bi bi-check-circle-fill text-success"></i> Each course comes with multiple sections and personalized learning resources.</li>
                                    <li><i className="bi bi-check-circle-fill text-success"></i> 1 live class for each section to clarify doubts and foster better understanding.</li>
                                    <li><i className="bi bi-check-circle-fill text-success"></i> Certificates awarded after course completion, recognized globally.</li>
                                    <li><i className="bi bi-check-circle-fill text-success"></i> Flexible learning schedule to suit your pace and needs.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row text-center mt-5 stats-section">
                                <div className="col-md-4">
                                    <div className="stats-circle">
                                        <span>6,900+</span>
                                        <small>Courses</small>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="stats-circle mt-5">
                                        <span>55K</span>
                                        <small>Instructors</small>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="stats-circle">
                                        <span>40M</span>
                                        <small>Learners</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
            <div className="container feature-section mt-3">
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <div className="feature-card p-4">
                            <img src="https://via.placeholder.com/80" alt="Personalized learning" />
                            <h5 className="fw-bold">Personalized Learning</h5>
                            <p className="text-muted">Our platform offers personalized learning experiences that allow students to progress at their own pace, filling in knowledge gaps while accelerating their understanding.</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="feature-card p-4">
                            <img src="https://via.placeholder.com/80" alt="Interactive classes" />
                            <h5 className="fw-bold">Interactive Live Classes</h5>
                            <p className="text-muted">Each course section includes a live class, giving students an opportunity to engage with instructors, ask questions, and reinforce their learning.</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="feature-card p-4">
                            <img src="https://via.placeholder.com/80" alt="Global certifications" />
                            <h5 className="fw-bold">Global Certifications</h5>
                            <p className="text-muted">Upon completing any course, students receive a globally recognized certificate that can be added to resumes or shared with employers.</p>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <p>Want to join us? See our <a href="/" className="text-danger">open positions</a></p>
                </div>
            </div>
            <br /><br />
            <div className="bg-light jumbotron mt-3 p-5">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row g-3">
                            <div className="col-6">
                                <img src="https://via.placeholder.com/150" alt="Team member" className="mission-image" />
                            </div>
                            <div className="col-6">
                                <img src="https://via.placeholder.com/150" alt="Team member" className="mission-image" />
                            </div>
                            <div className="col-6">
                                <img src="https://via.placeholder.com/150" alt="Team member" className="mission-image" />
                            </div>
                            <div className="col-6">
                                <img src="https://via.placeholder.com/150" alt="Team member" className="mission-image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center">
                        <div className="mission-text">
                            <h2 className="fw-bold">Our Mission</h2>
                            <p>
                                We are here to revolutionize education. Our platform was built to empower learners by offering engaging courses that blend flexible online learning with live interactions. We are driven by the belief that education is the key to unlocking opportunities, and we aim to make high-quality learning accessible to all.
                            </p>
                            <p className="highlight-text">
                                Join us in shaping the future of education.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="container my-5 p-5">
                <h2 className="text-center mb-4">Meet Our Leadership Team</h2>
                <p className="text-center">
                    Our leadership team is composed of experienced professionals from various fields, dedicated to providing you with the best online education experience possible.
                </p>
                <div className="row justify-content-center">
                    <div className="col-md-3 text-center">
                        <div className="card border-1">
                            <img src="https://via.placeholder.com/150" className="card-img-top rounded-circle mx-auto mt-4" style={{ width: "100px" }} alt="Alex Smith" />
                            <div className="card-body">
                                <h5 className="card-title">Alex Smith</h5>
                                <p className="card-text">Founder and CEO</p>
                                <div>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-facebook"></i></a>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-twitter"></i></a>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-center">
                        <div className="card border-1">
                            <img src="https://via.placeholder.com/150" className="card-img-top rounded-circle mx-auto mt-4" style={{ width: "100px" }} alt="Rose Taylor" />
                            <div className="card-body">
                                <h5 className="card-title">Rose Taylor</h5>
                                <p className="card-text">President and Co-CEO</p>
                                <div>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-facebook"></i></a>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-twitter"></i></a>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-center">
                        <div className="card border-1">
                            <img src="https://via.placeholder.com/150" className="card-img-top rounded-circle mx-auto mt-4" style={{ width: "100px" }} alt="Jef Collin" />
                            <div className="card-body">
                                <h5 className="card-title">Jef Collin</h5>
                                <p className="card-text">Chief Technology Officer</p>
                                <div>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-facebook"></i></a>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-twitter"></i></a>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-center">
                        <div className="card border-1">
                            <img src="https://via.placeholder.com/150" className="card-img-top rounded-circle mx-auto mt-4" style={{ width: "100px" }} alt="Jeffar Brown" />
                            <div className="card-body">
                                <h5 className="card-title">Jeffar Brown</h5>
                                <p className="card-text">Chief Information Officer</p>
                                <div>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-facebook"></i></a>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-twitter"></i></a>
                                    <a href="/" className="text-dark mx-1"><i className="fa fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
